import React, { useContext, useEffect, useState } from "react";
import RoundComponent from "./round";
import { IRound } from "../../../../utils/types/roundType";
import { IActivity } from "../../../../utils/types/activityType";
import { ReducerContext } from "../../../../libs/routers/privateRoute";
import {
  FAILED,
  LOADING,
  SUCCESS,
} from "../../../../utils/constants/constLoad";
import { updatePackageTour } from "../../../../services/tours/tourAction";

function Day() {
  const reducerContext = useContext(ReducerContext);
  const [days, setDays] = useState<number[]>([]);
  const onSelectDay = (daysParms: number[]) => {
    setDays(daysParms);
  };
  const [roundsItem, setRoundItem] = useState<IRound[]>([]);
  const addRound = (value: string, roundId: string, dayType: number) => {
    const data: IRound = {
      dayType,
      round: value,
      _id: roundId,
      activities: [],
    };
    setRoundItem((prev) => [...prev, data]);
  };
  const addActivityToRound = (
    roundId: string,
    dayType: number,
    activity: IActivity
  ) => {
    setRoundItem((prev) => {
      const index = prev.findIndex((round) => round._id === roundId);
      const data = prev[index];
      data.activities.push(activity);
      prev.splice(index, 1, data);
      return [...prev];
    });
  };
  const deleteActivity = (
    roundId: string,
    dayType: number,
    activity: IActivity
  ) => {
    setRoundItem((prev) => {
      const index = prev.findIndex(
        (round) => round._id === roundId && round.dayType === dayType
      );
      const data = prev[index];
      let result = data.activities.filter((a) => a._id !== activity._id);
      data.activities = result;
      prev.splice(index, 1, data);
      return [...prev];
    });
  };
  const deleteRound = (roundId: string) => {
    setRoundItem((prev) => {
      let result = prev.filter((r) => r._id !== roundId);
      return result;
    });
  };
  const roundItems = () => {
    return days.map((day) => (
      <div key={day}>
        <RoundComponent
          dayType={day}
          roundsItem={roundsItem}
          addRound={addRound}
          addActivity={addActivityToRound}
          deleteActivity={deleteActivity}
          deleteRound={deleteRound}
          key={day}
        />
      </div>
    ));
  };
  const onSubmit = async () => {
    try {
      reducerContext?.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      if (reducerContext.packageTours.tour) {
        const data: any = {
          packageName: reducerContext.packageTours.tour.packageName,
          price: reducerContext.packageTours.tour.price,
          description: reducerContext.packageTours.tour.description,
          dayForrent: reducerContext.packageTours.tour.dayForrent,
          mark: reducerContext.packageTours.tour.mark,
          introduce: reducerContext.packageTours.tour?.introduce || "",
          packageImage: reducerContext.packageTours.tour.packageImage,
          createdBy: reducerContext.packageTours.tour.createdBy,
          contactAdmin: reducerContext.packageTours.tour.contactAdmin,
          round: roundsItem.map((r) => ({
            dayType: r.dayType,
            round: r.round,
            activities: r.activities.map((a) => a._id),
          })),
          dayTrips: days.length === 1 ? "1d" : "2d1n",
        };
        console.log(data);

        await updatePackageTour(
          reducerContext.packageTours.tour._id || "",
          data
        );
        reducerContext?.loadDispatch({
          type: SUCCESS,
          payload: {
            fetchStatus: SUCCESS,
            message: "อัพเดทรอบเรียบร้อย",
          },
        });
      }
    } catch (error) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: "อัพเดทรอบล้มเหลว" },
      });
    }
  };
  useEffect(() => {
    if (reducerContext.packageTours.tour) {
      setRoundItem(reducerContext.packageTours.tour.round);
      setDays(
        reducerContext.packageTours.tour.dayTrips === "1d" ? [1] : [1, 2]
      );
    }
  }, []);
  return (
    <div className="flex justify-center">
      <div>
        <div className="flex justify-center">
          <button
            onClick={() => onSelectDay([1])}
            className="w-36 h-10 bg-white border border-violet-500 rounded-lg shadow-sm shadow-violet-400 mx-1"
          >
            วันเดย์ทริป
          </button>
          <button
            onClick={() => onSelectDay([1, 2])}
            className="w-36 h-10 bg-white border border-violet-500 rounded-lg shadow-sm shadow-violet-400"
          >
            สองวันหนึ่งคืน
          </button>
        </div>

        <div className="w-auto">{roundItems()}</div>
        <button
          className="bg-violet-500 rounded-md text-white w-full p-2 my-4"
          onClick={() => onSubmit()}
        >
          อัพเดทรอบ
        </button>
      </div>
    </div>
  );
}

export default Day;
