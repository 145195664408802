import axios from "../../libs/axios";
import {
  FETCHS_CONTACTS,
  FETCH_CONTACT,
} from "../../utils/constants/constContact";
import { IContactAdmin } from "../../utils/types/contactAdminType";
import { IActionContact } from "./contactReducer";

export const fetchContacts = async (): Promise<IActionContact> => {
  const resp = await axios.get("/guest/contact/admin");
  return {
    type: FETCHS_CONTACTS,
    payload: { contacts: resp.data, contact: null },
  };
};
export const fetchContact = async (id: string): Promise<IActionContact> => {
  const resp = await axios.get(`/guest/contact/admin/${id}`);
  return {
    type: FETCH_CONTACT,
    payload: { contacts: [], contact: resp.data },
  };
};
export const upsertContact = async (data: IContactAdmin,id:string) => {
  await axios.put(`/admin/contact/${id}`, data);
};

export const createContact = async (data: IContactAdmin) => {
  await axios.post(`/admin/contact`, data);
};

export const deleteContact = async (
  id: string
) => {
  await axios.delete(
    `/admin/contact/${id}`
  );
};
