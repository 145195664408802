import axios from "../../libs/axios";
import { FETCH_ACTIVITIES } from "../../utils/constants/constBusiness";
import { IActivity } from "../../utils/types/activityType";
import { IActivityAction } from "./activityReducer";
export const fetchActivities = async (): Promise<IActivityAction> => {
  const response = await axios.get("/activity/populate/business");
  return { type: FETCH_ACTIVITIES, payload: response.data };
};

export const fetchActivity = async (id:string):Promise<IActivity> => {
  const response = await axios.get(`/activity/${id}`)
  return response.data;
}

export const createActivity = async (activity: IActivity) => {
  await axios.post("/activity", activity);
};

export const updateActivity = async (activity: IActivity) => {
  await axios.put(`/activity/${activity._id}`, activity);
};

export const deleteActivity = async (id: string) => {
  await axios.delete(`/activity/${id}`);
};
