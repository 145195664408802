import axios from "../../libs/axios";
import { USER_INVALID, USER_LOGIN } from "../../utils/constants/constUser";
import { IUserAction } from "./reducer";
export const userLogin = async (
  username: string,
  password: string
): Promise<IUserAction> => {
  const response = await axios.post("/user/login", { username, password });
  if (response.status === 401 || response.data.roles !== "admin") return { type: USER_INVALID, payload: null };
  return { type: USER_LOGIN, payload: response.data };
};
