import React, { useContext, useEffect, useState } from "react";
import TableTours from "./components/tableTours";
import CarouselTours from "./components/carouselTours";
import { Add, Preview, TableRows } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReducerContext } from "../../libs/routers/privateRoute";
import { FAILED, LOADED, LOADING } from "../../utils/constants/constLoad";
import {
  deletePackageTour,
  fetchPackageTours,
} from "../../services/tours/tourAction";
export default function PackageTourList() {
  const reducerContext = useContext(ReducerContext);
  const navigate = useNavigate();
  const [isTable, setIsTable] = useState<boolean>(false);
  const handleIcon = (value: boolean) => {
    setIsTable(!isTable);
  };
  const onFetchTours = async () => {
    try {
      reducerContext?.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      const resp = await fetchPackageTours();
      reducerContext.packageTourDispatch(resp);
      reducerContext?.loadDispatch({
        type: LOADED,
        payload: { fetchStatus: LOADED, message: "" },
      });
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  const onDeleteTour = async (id: string) => {
    try {
      reducerContext?.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      await deletePackageTour(id);
      await onFetchTours();
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  const onEdit = (id: string) => {
    navigate(`/edit/tour/${id}`);
  };
  // fetch package tours list
  useEffect(() => {
    onFetchTours();
  }, []);

  return (
    <div>
      <div className="flex justify-between gap-5 my-3 mx-10">
        <div>
          <Tooltip title="สร้างแพ็คเกจทัวร์">
            <button
              onClick={() => navigate("/create/tour")}
              className="bg-violet-500 text-white rounded-md"
            >
              <Add />
            </button>
          </Tooltip>
        </div>
        <div>
          {isTable ? (
            <button onClick={() => handleIcon(false)}>
              <Preview color="primary" />
            </button>
          ) : (
            <button onClick={() => handleIcon(true)}>
              <TableRows color="primary" />
            </button>
          )}
        </div>
      </div>
      {isTable ? (
        <TableTours onDelete={onDeleteTour} onEdit={onEdit} />
      ) : (
        <CarouselTours onDelete={onDeleteTour} onEdit={onEdit} />
      )}
    </div>
  );
}
