import React, { useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { ActivityContext, IActivityContext } from "../manageActivity";

function ActivityDropzone() {
  const activityContext = useContext<IActivityContext | null>(ActivityContext);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Do something with the files
    const date = new Date();
    const dateParse = Date.parse(date.toISOString())
    const convertFileName: File[] = [];
    for (const file of acceptedFiles) {
      convertFileName.push(
        new File([file], `${dateParse}-${file.name}`, { type: file.type })
      );
    }
    activityContext?.onSelectFile(convertFileName);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
    multiple: true,
  });
  const thumbs = activityContext?.files.map((file, index) => (
    <img
      src={URL.createObjectURL(file)}
      className="w-32 h-32 mx-2 my-2"
      alt={file.name}
      key={`${file.name}-${index}`}
      onLoad={() => {
        URL.revokeObjectURL(URL.createObjectURL(file));
      }}
    />
  ));

  return (
    <div className="w-full h-full" {...getRootProps()}>
      <label className="flex flex-col items-center justify-center w-full h-full border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-white  hover:bg-gray-100 dark:border-gray-400 dark:hover:border-gray-500">
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            aria-hidden="true"
            className="w-10 h-10 mb-3 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            ></path>
          </svg>
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span> activity
            images
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400"> PNG, JPG </p>
        </div>
        <input {...getInputProps()} />
        <div className="grid md:grid-cols-4 sm:grid-cols-1 h-80  overflow-auto scrollbar-hide">
          {thumbs}
        </div>
      </label>
    </div>
  );
}

export default ActivityDropzone;
