import React, { useContext } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ReducerContext } from "../../../../libs/routers/privateRoute";
import { IActivity } from "../../../../utils/types/activityType";
import { IRound } from "../../../../utils/types/roundType";

interface IActivityDialogComp {
  open: boolean;
  handleClose(): void;
  round: IRound | null;
  addActivity(roundId: string, dayType: number, activity: IActivity): void;
}
function ActivityDialog({
  open,
  handleClose,
  round,
  addActivity,
}: IActivityDialogComp) {
  const reducerContext = useContext(ReducerContext);
  const isCheckSelected = (activityId: string | undefined) => {
    if (!activityId) return false;
    if (!round) return false;
    const isChecked =
      round.activities?.filter((activity) => activity._id === activityId)
        .length > 0;
    return isChecked;
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`เลือกกิจกรรมของรอบ ${round?.round}`}
      </DialogTitle>
      <DialogContent>
        <div className="grid grid-cols-3 gap-3">
          {reducerContext?.activityState.activities.map((data) => (
            <div
              className={`w-36 bg-white rounded h-auto`}
              onClick={() => addActivity(round?._id, round?.dayType || 1, data)}
              key={data._id}
            >
              <img
                src={`https://sgp1.digitaloceanspaces.com/cbt-thailand/uploads/${data.imageRef}`}
                alt={data.activityName}
                className={`w-36 h-40 object-cover rounded-lg ${
                  isCheckSelected(data?._id) &&
                  " transition delay-75 ease-in-out transform translate-y-1 scale-110 mb-3"
                }`}
              />
              <p
                className={
                  isCheckSelected(data?._id)
                    ? "text-violet-500 text-lg ease-in-out"
                    : "text-sm text-gray-800"
                }
              >
                {data.activityName}
              </p>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ActivityDialog;
