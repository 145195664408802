import React, { useContext } from "react";
import Navbar from "./navbar";

import Loading from "../loading";
import { ReducerContext } from "../../libs/routers/privateRoute";
interface ILayout {
  children: JSX.Element;
}

function Layout({ children }: ILayout) {
  const reducerContext = useContext(ReducerContext);

  return (
    <div className="bg-gray-100 h-screen">
      <Navbar />
      <Loading
        fetchStatus={reducerContext.loadState.fetchStatus}
        message={reducerContext.loadState.message}
      />
      {children}
    </div>
  );
}

export default Layout;
