import { TextFormat, Timelapse } from "@mui/icons-material";
import React, { useContext } from "react";
import { TourContext } from "../manageTours";
interface ICardProgress {
  show: boolean;
}
function CardProgress({ show }: ICardProgress) {
  const tourContext = useContext(TourContext);
  return (
    <div className="flex justify-center my-5">
      <div className="grid grid-cols-2 gap-10">
        <div
          className={`w-40 h-24 rounded-md bg-white shadow-md  text-center ${
            tourContext.selectCard === "form" &&
            "transition delay-75 transform scale-110 border-2 border-violet-300 shadow-violet-300"
          } `}
          onClick={() => tourContext.onChangeCard("form")}
        >
          <TextFormat fontSize="large" className="my-3 text-violet-500" />
          <h2 className="text-lg">ข้อมูลแพ็คเกจ</h2>
        </div>
        {show && (
          <div
            className={`w-40 h-24 rounded-md bg-white shadow-md  text-center ${
              tourContext.selectCard === "round" &&
              "transition delay-75 transform scale-110 border-2 border-violet-300 shadow-violet-300"
            } `}
            onClick={() => tourContext.onChangeCard("round")}
          >
            <Timelapse fontSize="large" className="my-3 text-violet-500" />
            <h2 className="text-lg">รอบ & กิจกรรม</h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default CardProgress;
