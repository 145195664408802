import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import PrivateRoute from "./libs/routers/privateRoute";
import { getRole } from "./libs/localStorage";

function App() {
  const [role, setRole] = useState<string>(getRole());

  return (
    <BrowserRouter>
      <PrivateRoute role={role} setRole={setRole} />
    </BrowserRouter>
  );
}

export default App;
