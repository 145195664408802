import axios from "../../libs/axios";

export const uploadFile = async (files: File[]) => {
  if (files.length > 0) {
    const formData = new FormData();
    files.forEach((sources) => {
      formData.append("images", sources);
    });
    await axios.post("/upload/file/multiple", formData);
  }
};
