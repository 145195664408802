import { Add, Delete } from "@mui/icons-material";
import React, { useState } from "react";
import ActivityDialog from "./activityDialog";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { IRound } from "../../../../utils/types/roundType";
import { IActivity } from "../../../../utils/types/activityType";
import { v4 as uuidv4, v4 } from "uuid";

interface IRoundItem {
  dayType: number;
  roundsItem: IRound[];
  addRound(value: string, roundId: string, dayType: number): void;
  addActivity(roundId: string, dayType: number, activity: IActivity): void;
  deleteActivity(roundId: string, dayType: number, activity: IActivity): void;
  deleteRound(roundId: string): void;
}
function Round({
  dayType,
  roundsItem,
  addRound,
  addActivity,
  deleteActivity,
  deleteRound,
}: IRoundItem) {
  // const tourContext = useContext(TourContext);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectRound, setSelectRound] = useState<IRound | null>(null);
  const handleClose = () => setSelectRound(null);
  // const pass = useId();
  return (
    <div className="bg-white my-5 w-full p-4">
      <div>
        <label>{`วันที่ ${dayType}`}</label>
        <div className="flex justify-between items-center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["MobileTimePicker"]}>
              <DemoItem label="เริ่มรอบกี่โมง">
                <MobileTimePicker
                  defaultValue={dayjs("2022-04-17T15:30")}
                  format="hh:mm"
                  onChange={(value) =>
                    setStartTime(`${value?.hour()}:${value?.minute()}`)
                  }
                />
              </DemoItem>
              <DemoItem label="จบรอบกี่โมง">
                <MobileTimePicker
                  defaultValue={dayjs("2022-04-17T15:30")}
                  format="hh:mm"
                  onChange={(value) =>
                    setEndTime(`${value?.hour()}:${value?.minute()}`)
                  }
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>

          <button
            onClick={() => addRound(`${startTime} - ${endTime}`, v4(), dayType)}
            disabled={startTime === "" || endTime === ""}
            className="text-center text-lg border border-violet-300 w-5 h-10 mx-4 mt-7"
          >
            +
          </button>
        </div>
      </div>

      {roundsItem
        .filter((a) => a.dayType === dayType)
        .map((item, index) => (
          <>
            <div key={index} className="flex  justify-between my-2 mx-2">
              <label>{item.round}</label>
              <div>
                <button
                  className=" rounded-md shadow-md mx-2"
                  onClick={() => setSelectRound(item)}
                >
                  <Add color="primary" />
                </button>
                <button
                  className="rounded-md shadow-md"
                  onClick={() => deleteRound(item._id)}
                >
                  <Delete color="error" />
                </button>
              </div>
            </div>
            {item.activities.map((a, i) => (
              <div className="flex justify-between" key={a._id}>
                <li key={i} className="ml-5">
                  {a.activityName}
                </li>
                <button
                  className="text-red-500 underline"
                  onClick={() => deleteActivity(item._id, item.dayType, a)}
                >
                  ลบ
                </button>
              </div>
            ))}
          </>
        ))}
      <ActivityDialog
        round={selectRound}
        open={selectRound !== null}
        handleClose={handleClose}
        addActivity={addActivity}
      />
    </div>
  );
}

export default Round;
