import React, { createContext, useReducer } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ConfigRoute from "./index";
import {
  ILoad,
  ILoadAction,
  initialLoading,
  reducerLoading,
} from "../../services/load/reducer";
import { IActivityReducer } from "../../services/activity/activityReducer";
import { IActivityAction } from "../../services/activity/activityReducer";
import { initialActivity } from "../../services/activity/activityReducer";
import { reducerActivity } from "../../services/activity/activityReducer";
import {
  IActionTour,
  IStateTour,
  initialTours,
  tourReducer,
} from "../../services/tours/tourReducer";
import { IActionContact, IStateContact, contactReducer, initialContacts } from "../../services/contacts/contactReducer";

interface IPrivateRoute {
  role: string;
  setRole: React.Dispatch<React.SetStateAction<string>>;
}
export interface IReducerContext {
  loadState: ILoad;
  loadDispatch: React.Dispatch<ILoadAction>;
  activityState: IActivityReducer;
  activityDispatch: React.Dispatch<IActivityAction>;
  packageTours: IStateTour;
  packageTourDispatch: React.Dispatch<IActionTour>;
  contactState: IStateContact,
  contactDispatch: React.Dispatch<IActionContact>;
}
export const ReducerContext = createContext<IReducerContext>({
  loadState: initialLoading,
  loadDispatch: () => {},
  activityState: initialActivity,
  activityDispatch: () => {},
  packageTours: initialTours,
  packageTourDispatch: () => {},
  contactState: initialContacts,
  contactDispatch: () => {}
});
export default function PrivateRoute({ role, setRole }: IPrivateRoute) {
  const userRole = role || "guest";
  const allowRoutes = ConfigRoute[userRole].allowRoutes;
  const redirectRoutes = ConfigRoute[userRole].redirectRoutes;
  const [loadState, loadDispatch] = useReducer(reducerLoading, initialLoading);
  const [activityState, activityDispatch] = useReducer(
    reducerActivity,
    initialActivity
  );
  const [packageState, packageDispatch] = useReducer(tourReducer, initialTours);
  const [contactState, contactDispatch] = useReducer(contactReducer,initialContacts)
  const reducerContext = {
    loadState,
    loadDispatch,
    activityState,
    activityDispatch,
    packageTours: packageState,
    packageTourDispatch: packageDispatch,
    contactState,
    contactDispatch,
  };

  return (
    <Routes>
      {allowRoutes?.map((route: any) => (
        <Route
          path={route.url}
          key={route.url}
          element={
            <ReducerContext.Provider value={reducerContext}>
              <route.component role={role} setRole={setRole} />
            </ReducerContext.Provider>
          }
        />
      ))}
      <Route path="*" element={<Navigate replace to={redirectRoutes} />} />
    </Routes>
  );
}
