import {
  FETCH_PLACES,
  SELECT_PLACE,
} from "../../utils/constants/constBusiness";
import { IPlace } from "../../utils/types/placeType";

export interface IStateBusiness {
  places: IPlace[];
  selected?: IPlace;
}
export interface IActionBusiness {
  type: string;
  payload: IStateBusiness;
}
export const initialBusiness: IStateBusiness = {
  places: [],
  selected: {
    _id: "",
    placeName: "",
    address: "",
    description: "",
    imageList: [],
    videoRef: "",
    ratingCount: 0,
    point: 0,
    lat: 0,
    lng: 0,
    ownerId: "",
    price: 0,
  },
};

export const businessReducer = (
  state: IStateBusiness,
  action: IActionBusiness
) => {
  switch (action.type) {
    case FETCH_PLACES:
      const data: IStateBusiness = {
        places: action.payload.places,
        selected: state.selected,
      };
      return data; // send businesses only
    case SELECT_PLACE:
      const selectes: IStateBusiness = {
        places: state.places,
        selected: action.payload.selected,
      };
      return selectes; // send selected business only
    default:
      return state;
  }
};
