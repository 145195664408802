import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ReducerContext } from "../../../libs/routers/privateRoute";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
interface IContactComponent {
  onDelete(id: string): void;
  onEdit(id: string): void;
}
function TableContacts({ onDelete, onEdit }: IContactComponent) {
  const reducerContext = useContext(ReducerContext);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ชื่อ - นามสกุล</TableCell>
            <TableCell align="right">เบอร์โทรศัพท์</TableCell>
            <TableCell align="right">ที่อยู่อาศัย</TableCell>

            <TableCell align="right">ประเภทการชำระเงิน</TableCell>
            <TableCell align="right">เลขบัญชี</TableCell>
            <TableCell align="right">action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reducerContext.contactState.contacts.map((row) => (
            <TableRow
              key={row._id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.fullName}
              </TableCell>
              <TableCell align="right">{row.phoneNumber}</TableCell>
              <TableCell component="th" scope="row">
                {row.address}
              </TableCell>
              <TableCell align="right">{row.typePayment}</TableCell>
              <TableCell align="right">{row.accountPayment}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => onEdit(row._id || "")}>
                  <Edit color="warning" />
                </IconButton>
                <IconButton onClick={() => onDelete(row._id || "")}>
                  <Delete color="error" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableContacts;
