import React, { createContext, useContext, useEffect, useState } from "react";
import Layout from "../../components/layouts";
import { IContactAdmin } from "../../utils/types/contactAdminType";
import { useParams } from "react-router-dom";
import { ReducerContext } from "../../libs/routers/privateRoute";
import { FAILED, LOADED, LOADING } from "../../utils/constants/constLoad";
import { fetchContact } from "../../services/contacts/contactAction";
import DropZoneContact from "./components/dropzone";
import FormContact from "./components/form";

export interface IContactContext {
  profile: File | null;
  paymentImage: File | null;
  onSelectFile(file: File, type: string): void;
}
export const ContactContext = createContext<IContactContext>({
  profile: null,
  paymentImage: null,
  onSelectFile: () => {},
});
function ManageContact() {
  const reducerContext = useContext(ReducerContext);
  const { id } = useParams();
  const [profile, setProfile] = useState<File | null>(null);
  const [paymentImage, setPaymentImage] = useState<File | null>(null);
  const onSelectFile = (file: File, type: string) => {
    // type === profile | payment
    if (type === "profile") {
      setProfile(file);
    }
    if (type === "payment") {
      setPaymentImage(file);
    }
  };
  const fetchContactById = async (id: string) => {
    try {
      reducerContext?.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      const result = await fetchContact(id);
      reducerContext.contactDispatch(result);
      reducerContext?.loadDispatch({
        type: LOADED,
        payload: { fetchStatus: LOADED, message: "" },
      });
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  const contactValue: IContactContext = {
    profile,
    paymentImage,
    onSelectFile,
  };
  useEffect(() => {
    if (id) {
      fetchContactById(id);
    }
  }, [id]);
  return (
    <Layout>
      <ContactContext.Provider value={contactValue}>
        <div className=" grid grid-cols-2 mx-5 my-10">
          <div>
            <div className="flex justify-center">
              <DropZoneContact
                file={profile}
                onSelectFile={onSelectFile}
                type="profile"
                imageRef={reducerContext.contactState.contact?.profileRef}
              />
            </div>
            <FormContact />
          </div>

          <div className="flex justify-center items-center">
            <DropZoneContact
              file={paymentImage}
              onSelectFile={onSelectFile}
              type="payment"
              imageRef={reducerContext.contactState.contact?.imagePayment}
            />
          </div>
        </div>
      </ContactContext.Provider>
    </Layout>
  );
}

export default ManageContact;
