import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ReducerContext } from "../../../libs/routers/privateRoute";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

function TableActivity() {
  
  const reducerContext = useContext(ReducerContext);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ชื่อกิจกรรม</TableCell>
            <TableCell>ชื่อของธุรกิจ</TableCell>
            <TableCell align="right">ราคา</TableCell>
            <TableCell align="right">จำนวน</TableCell>
            <TableCell align="right">สมาชิกขั้นต่ำ</TableCell>
            <TableCell align="right">action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reducerContext.activityState.activities.map((row: any) => (
            <TableRow
              key={row._id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.activityName}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.businessId.businessName || "ไม่พบรายชื่อของธุรกิจ"}
              </TableCell>
              <TableCell align="right">{row.price}</TableCell>
              <TableCell align="right">{row.unit}</TableCell>
              <TableCell align="right">{row.minPerson}</TableCell>
              <TableCell align="right">
                <IconButton>
                  <Edit color="warning" />
                </IconButton>
                <IconButton>
                  <Delete color="error" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableActivity;
