import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { Dangerous, CheckOutlined } from "@mui/icons-material";
import {
  FAILED,
  LOADED,
  LOADING,
  SUCCESS,
} from "../../utils/constants/constLoad";
import { ILoad } from "../../services/load/reducer";

const Loading = ({ fetchStatus, message }: ILoad) => {
  const [open, setOpen] = useState<boolean>(fetchStatus !== LOADED);

  useEffect(() => {
    setOpen(fetchStatus !== LOADED);
  }, [fetchStatus]);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={() => setOpen(!open)}
    >
      {fetchStatus === LOADING && <CircularProgress color="inherit" />}
      {[SUCCESS, FAILED].includes(fetchStatus) && (
        <div className="flex items-center justify-center bg-gray-100 rounded-lg">
          <div className="bg-white p-8 rounded-lg text-center shadow-xl w-96 text-cyan-900">
            {fetchStatus === FAILED ? (
              <Dangerous color="error" fontSize="large" />
            ) : (
              <CheckOutlined color="success" fontSize="large" />
            )}

            <h1>{message}</h1>
          </div>
        </div>
      )}
    </Backdrop>
  );
};

export default Loading;
