import React, { useContext } from "react";
import { ReducerContext } from "../../../libs/routers/privateRoute";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface ICarouselPackageTours {
  onDelete(id: string): void;
  onEdit(id: string): void;
}
function CarouselTours({ onDelete, onEdit }: ICarouselPackageTours) {
  const reducerContext = useContext(ReducerContext);

  return (
    <div className="flex justify-center my-4">
      <div className="grid md:grid-cols-4 sm:grid-cols-1 gap-4">
        {reducerContext.packageTours.packageTours.map((tour) => (
          <div className="bg-white rounded-md w-48 h-52" key={tour._id}>
            <div className="relative">
              <img
                src={`https://sgp1.digitaloceanspaces.com/cbt-thailand/uploads/${tour.packageImage}`}
                alt={tour.packageName}
                className="object-cover h-40 w-48 rounded-md opacity-80"
              />
              <div className=" absolute right-0 top-0">
                <IconButton onClick={() => onDelete(tour._id || "")}>
                  <Delete color="error" />
                </IconButton>
              </div>
            </div>
            <div
              className=" hover:cursor-pointer hover:underline"
              onClick={() => onEdit(tour._id || "")}
            >
              <p className="text-lg truncate text-gray-600 p-2">
                {tour.packageName}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CarouselTours;
