import React, { useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { TourContext } from "../manageTours";
import { ReducerContext } from "../../../libs/routers/privateRoute";

function DropzoneImageTour() {
  const reducerContext = useContext(ReducerContext);
  const tourContext = useContext(TourContext);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Do something with the files
    const date = new Date();
    const dateParse = Date.parse(date.toISOString());
    if (acceptedFiles.length > 0) {
      tourContext.onSelectFile(
        new File(acceptedFiles, `${dateParse}-${acceptedFiles[0].name}`, {
          type: acceptedFiles[0].type,
        })
      );
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
  });
  const thumbs = (file: File) => (
    <img
      src={URL.createObjectURL(file)}
      className="rounded-full  w-56 h-56 object-cover"
      alt={file.name}
      key={`${file.name}`}
      onLoad={() => {
        URL.revokeObjectURL(URL.createObjectURL(file));
      }}
    />
  );
  return (
    <div>
      <div className="w-full h-full ml-40 mt-16" {...getRootProps()}>
        <label className="flex items-center justify-center w-56 h-56 rounded-full border-2 border-violet-300 shadow-violet-300 shadow-md cursor-pointer bg-white ">
          {tourContext.file && thumbs(tourContext.file)}
          {reducerContext.packageTours.tour && !tourContext.file && (
            <img
              src={`https://sgp1.digitaloceanspaces.com/cbt-thailand/uploads/${reducerContext.packageTours.tour.packageImage}`}
              className="rounded-full  w-56 h-56 object-cover"
              alt={reducerContext.packageTours.tour.packageImage}
            />
          )}
          {!tourContext.file && !reducerContext.packageTours.tour && (
            <div className="flex flex-col items-center justify-center pt-5 pb-6 ">
              <svg
                aria-hidden="true"
                className="w-10 h-10 mb-3 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">package tour images</span>
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                {" "}
                PNG, JPG{" "}
              </p>
            </div>
          )}

          <input {...getInputProps()} />
        </label>
      </div>
    </div>
  );
}

export default DropzoneImageTour;
