import {
  DELETE_ACTIVITIES,
  FETCH_ACTIVITIES,
} from "../../utils/constants/constBusiness";
import { IActivity } from "../../utils/types/activityType";

export interface IActivityAction {
  type: string;
  payload?: IActivity[];
  activityId?: string; // for delete
}
export interface IActivityReducer {
  activities: IActivity[];
}
export const initialActivity = {
  activities: [],
};

export const reducerActivity = (
  state: IActivityReducer,
  action: IActivityAction
) => {
  switch (action.type) {
    case FETCH_ACTIVITIES:
      return { activities: action.payload || [] };
    case DELETE_ACTIVITIES:
      const stateFilter: IActivity[] = state.activities.filter(
        (activity) => activity._id !== action.activityId
      );
      return {
        activities: stateFilter,
      };
    default:
      return state;
  }
};
