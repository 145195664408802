import { Avatar } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getToken, removeToken } from "../../libs/localStorage";
import jwtDecode from "jwt-decode";
import { IUser } from "../../utils/types/userType";
const Navbar = () => {
  const token = getToken();
  const user: IUser | null = jwtDecode(token || "");
  const navigate = useNavigate();
  const onLogout = () => {
    removeToken();
    window.location.reload();
  };
  return (
    <header className="bg-violet-500 h-16 flex items-center text-white text-lg">
      <nav className="flex justify-between items-center w-full">
        <div
          className="flex items-center gap-4 cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img
            alt="logo"
            src="/assets/takhientia.png"
            className="w-30 h-16 object-contain"
          />
          <h1>Takhian Tia</h1>
        </div>
        <div className="flex gap-5 mx-4 items-center">
          <Avatar
            alt="user profile"
            src={
              user?.profileRef
                ? `https://sgp1.digitaloceanspaces.com/cbt-thailand/uploads/${user?.profileRef}`
                : ""
            }
            className="sm:visible"
          />
          <span>
            {user?.firstName} {user?.lastName}
          </span>
          <button
            className="bg-white text-red-500 rounded-md p-1 hover:bg-red-400 hover:text-white"
            onClick={onLogout}
          >
            Log out
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
