import React, { createContext, useContext, useEffect, useState } from "react";
import Layout from "../../components/layouts";
import FormTour from "./components/form";
import CardProgress from "./components/cardProgress";
import { IActivityRound } from "../../utils/types/roundType";
import Day from "./components/round/day";
import { ReducerContext } from "../../libs/routers/privateRoute";
import { FAILED, LOADED, LOADING } from "../../utils/constants/constLoad";
import { fetchActivities } from "../../services/activity/activityAction";
import {
  fetchContact,
  fetchContacts,
} from "../../services/contacts/contactAction";
import { useParams } from "react-router-dom";
import { fetchPackageTour } from "../../services/tours/tourAction";

export interface ITourContext {
  selectCard: string; // card progress [form,round,contact]
  onChangeCard(card: string): void;
  activities: IActivityRound[];
  onSelectedActivityRound(activity: IActivityRound): void;
  onSelectFile(file: File): void;
  file: File | null;
  open: boolean;
  handleOpen(status: boolean): void;
}
export const TourContext = createContext<ITourContext>({
  selectCard: "",
  onChangeCard: () => {},
  activities: [],
  onSelectedActivityRound: () => {},
  onSelectFile: () => {},
  file: null,
  open: false,
  handleOpen: () => {},
});
function ManageTours() {
  const reducerContext = useContext(ReducerContext);
  const { id } = useParams();
  const [selectCard, setSelectCard] = useState("form");
  const [activities, setActivities] = useState<IActivityRound[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [open, setOpen] = useState(false);
  const onChangeCard = (card: string) => {
    setSelectCard(card);
  };
  const onFetchTour = async (id: string) => {
    try {
      reducerContext?.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      const result = await fetchPackageTour(id);
      reducerContext.packageTourDispatch(result);
      reducerContext?.loadDispatch({
        type: LOADED,
        payload: { fetchStatus: LOADED, message: "" },
      });
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  const onFetchActivity = async () => {
    try {
      const result = await fetchActivities();
      reducerContext.activityDispatch(result);
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  const onFetchContacts = async () => {
    try {
      const result = await fetchContacts();
      reducerContext.contactDispatch(result);
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  useEffect(() => {
    if (reducerContext.activityState.activities.length === 0) {
      onFetchActivity();
    }
    if (reducerContext.contactState.contacts.length === 0) {
      onFetchContacts();
    }
    if (id && !reducerContext.packageTours.tour) {
      onFetchTour(id);
    }
  }, []);
  const onSelectedActivityRound = (activity: IActivityRound) => {
    setActivities((pre) => [...pre, activity]);
  };
  const onSelectFile = (file: File) => {
    setFile(file);
  };

  const onHandleOpen = (status: boolean) => {
    setOpen(status);
  };

  const tourContext: ITourContext = {
    selectCard,
    onChangeCard,
    activities,
    onSelectedActivityRound,
    file,
    onSelectFile,
    open,
    handleOpen: onHandleOpen,
  };

  return (
    <Layout>
      <TourContext.Provider value={tourContext}>
        <div className="bg-gray-100">
          <CardProgress show={id !== undefined} />
          {selectCard === "form" && <FormTour />}
          {selectCard === "round" && <Day />}
        </div>
      </TourContext.Provider>
    </Layout>
  );
}

export default ManageTours;
