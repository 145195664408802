import axios, { AxiosError } from "axios";
import { getToken, removeToken } from "./localStorage";

// axios.defaults.baseURL = process.env.REACT_APP_API_URL || "https://cbt-takhiantia.com/api";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  (config: any) => {
    if (config.url?.includes("/user/login")) return config;
    const token = getToken();
    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (Response) => {
    return Response;
  },
  (error: AxiosError) => {
    if (
      error.response?.status === 401 &&
      !error.response.config.url?.includes("/user/login")
    ) {
      removeToken();
      window.location.reload();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
export default axios;
