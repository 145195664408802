import {
  FETCHS_PACKAGE_TOUR,
  FETCH_PACKAGE_TOUR,
} from "../../utils/constants/constPackageTour";
import { IPackage } from "../../utils/types/packageType";

export interface IStateTour {
  packageTours: IPackage[];
  tour: IPackage | null;
}
export interface IActionTour {
  type: string;
  payload: IStateTour;
}
export const initialTours: IStateTour = {
  packageTours: [],
  tour: null,
};

export const tourReducer = (state: IStateTour, action: IActionTour) => {
  switch (action.type) {
    case FETCHS_PACKAGE_TOUR:
      return action.payload;
    case FETCH_PACKAGE_TOUR:
      return action.payload;
    default:
      return state;
  }
};
