import React from "react";
import Hero from "./hero";
import Carousel from "./corasel";

function HomePages() {
  return (
    <>
      <Hero />
      <Carousel />
    </>
  );
}

export default HomePages;
