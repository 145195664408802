import React, { useContext } from "react";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ActivityContext, IActivityContext } from "../manageActivity";

function Business() {
  const activityContext = useContext<IActivityContext | null>(ActivityContext);

  return (
    <Dialog
      open={activityContext?.open || false}
      onClose={activityContext?.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"เลือกสถานที่หรือธุรกิจที่เป็นเจ้าของกิจกรรม"}
      </DialogTitle>
      <DialogContent>
        <div className="grid grid-cols-3 gap-3">
          {activityContext?.businesState.places.map((data) => (
            <div
              className={`w-36 bg-white rounded h-auto`}
              onClick={() => activityContext?.onSelectPlace(data)}
              key={data._id}
            >
              {data.imageList.length > 0 ? (
                <img
                  src={`https://sgp1.digitaloceanspaces.com/cbt-thailand/uploads/${data.imageList[0]}`}
                  alt={data.placeName}
                  className={`w-36 h-40 object-cover rounded-lg ${
                    activityContext?.businesState.selected?._id === data._id &&
                    " transition delay-75 ease-in-out transform translate-y-1 scale-110 mb-3"
                  }`}
                />
              ) : (
                <div className="w-36 h-40 bg-gray-100 rounded-lg text-center">
                  No Picture
                </div>
              )}

              <p
                className={
                  activityContext?.businesState.selected?._id === data._id
                    ? "text-violet-500 text-lg ease-in-out"
                    : "text-sm text-gray-800"
                }
              >
                {data.placeName}
              </p>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default Business;
