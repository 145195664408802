import React, { useContext } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { TourContext } from "../manageTours";
import { ReducerContext } from "../../../libs/routers/privateRoute";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton } from "@mui/material";
import { Check } from "@mui/icons-material";
import { FAILED } from "../../../utils/constants/constLoad";
import { fetchContact } from "../../../services/contacts/contactAction";
function ContactDialog() {
  const reducerContext = useContext(ReducerContext);
  const tourContext = useContext(TourContext);
  const fetchContactById = async (id: string) => {
    try {
      const result = await fetchContact(id);
      reducerContext.contactDispatch(result);
      tourContext.handleOpen(false);
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: {
          fetchStatus: FAILED,
          message: "เลือกผู้ดูแลล้มเหลว กรุณาลองใหม่อีกครั้ง",
        },
      });
    }
  };
  return (
    <Dialog
      open={tourContext.open}
      onClose={() => tourContext.handleOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"เลือกผู้ดูแลแพ็คเกจ"}</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 380 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ชื่อ - นามสกุล</TableCell>
                <TableCell align="right">เบอร์โทรศัพท์</TableCell>
                <TableCell align="right">ประเภทการชำระเงิน</TableCell>
                <TableCell align="right">เลขบัญชี</TableCell>
                <TableCell align="right">action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reducerContext.contactState.contacts.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.fullName}
                  </TableCell>
                  <TableCell align="right">{row.phoneNumber}</TableCell>
                  <TableCell align="right">{row.typePayment}</TableCell>
                  <TableCell align="right">{row.accountPayment}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => fetchContactById(row._id || "")}>
                      <Check color="warning" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}

export default ContactDialog;
