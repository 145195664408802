import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";

const CAROUSEL_DATA = [
  {
    url: "/assets/fasai-iko.jpeg",
    subURL: ["/assets/fasai-sub4.jpeg","/assets/fasai-sub3.jpeg","/assets/fasai-sub2.jpeg","/assets/fasai-sub1.jpeg"],
    label: "สวนฟ้าใสไอโกะ",
    description:"สวนฟ้าใสไอโกะ มีความสวยงามเป็นธรรมชาติ และมีการดูแลสวนมะพร้าวเป็นอย่างดี มีการตัดหญ้า และปลูกต้นไม้ ได้อย่างสวยงาม เป็นสวนมะพร้าวที่ไม่ใช้สารเคมีในการปราบศัตรู เช่น หนอนหัวดำ จากเดิมเคยใช้สารเคมี พอมาเข้าร่วมกับชมรมส่งเสริมการท่องเที่ยวโดยชุมชนตำบลตะเคียนเตี้ย ก้อเลิกใช้สารเคมีในการปราบหนอนหัวดำ และกลับมาใฃ้แตนเบียนแทน ทำให้สวยมะพร้าวสวยงามน่าเข้าเยี่ยมชม นักท่องเที่ยวเข้ามาเที่ยวในสวนมะพร้าวประทับใจในธรรมชาติที่สวยงามเป็นอย่างมาก และนักท่องเที่ยวก้อจะได้ทานมะพร้าวน้ำหอมจากสวน มีความหอมหวาน"
  },
  {
    url: "/assets/baan.jpeg",
    subURL: ["/assets/baan-sub2.jpeg","/assets/baan-sub3.jpeg","/assets/baan-sub5.jpeg","/assets/baan-sub6.jpeg"],
    label: "บ้านร้อยเสา",
    description: "ใครที่มาเที่ยวที่บ้านร้อยเสาจะได้สัมผัสวิถีชีวิตชุมชนอย่างแท้จริง เพราะบ้านนี้รวบรวมมรดกทางวัฒนธรรมของชุมชน ข้าวของเครื่องใช้สมัยโบราณ ตำรายารักษาโรค ตำราโหราศาสตร์ ปีนักษัตรไทย การดูฤกษ์ยาม ครกบดยา ภาชนะทองเหลืองสลักลาย และพระเครื่อง เป็นต้น ซึ่งของทุกชิ้นมีเรื่องราวมากด้วยคุณค่า ผู้ที่เดินทางมาเยี่ยมเยีอนยังมีโอกาสได้เรียนรู้ภูมิปัญญาท้องถิ่น เช่น การปลูกผักสวนครัวและสวนสมุนไพร การทำน้ำหมักชีวภาพ จากใบไม้และเศษซากผักที่เหลือใช้ งานหัตถกรรมพื้นบ้าน",
  },
];

const CarouselCard = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const onChangeCard = (index: number) => setCurrentIndex(index);
  return (
    <div className="flex justify-center">
      <Carousel
        showThumbs={false}
        showIndicators={false}
        onChange={(index) => onChangeCard(index)}
        // itemsToShow={3}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`${
                hasPrev ? "absolute" : "hidden"
              } top-0 bottom-0 left-0 flex justify-center items-center p-4 opacity-40 hover:opacity-100 cursor-pointer z-20`}
              onClick={clickHandler}
            >
              <ArrowBackIos />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`${
                hasNext ? "absolute" : "hidden"
              } top-0 bottom-0 right-0 flex justify-center items-center p-4 opacity-40 hover:opacity-100 cursor-pointer z-20 `}
              onClick={clickHandler}
            >
              <ArrowForwardIos />
            </div>
          );
        }}
      >
        {CAROUSEL_DATA.map((card) => (
          <section className=" flex flex-col items-center my-16 px-16">
            <h2 className="mb-4 font-bold">{card.label}</h2>
            <p className="mb-8 ">{card.description}</p>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
              <div className="col-span-2 md:col-span-3 md:row-span-2">
                <img
                  src={card.url}
                  alt="borabora1"
                  className="w-full h-full object-cover"
                />
              </div>

              {card.subURL?.map((url) => (
                <div>
                  <img
                    src={url}
                    alt="borabora2"
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </div>
          </section>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselCard;
