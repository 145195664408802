import { Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ReducerContext } from "../../../libs/routers/privateRoute";
import { FAILED, LOADING, SUCCESS } from "../../../utils/constants/constLoad";
import {
  createContact,
  upsertContact,
} from "../../../services/contacts/contactAction";
import { IContactAdmin } from "../../../utils/types/contactAdminType";
import { ContactContext } from "../manageContact";
import { uploadFile } from "../../../services/upload/uploadAction";
import { getToken } from "../../../libs/localStorage";
import jwtDecode from "jwt-decode";
import { IUser } from "../../../utils/types/userType";

export default function FormContact() {
  const reducerContext = useContext(ReducerContext);
  const contactContext = useContext(ContactContext);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [typePayment, setTypePayment] = useState("");
  const [accountPayment, setAccountPayment] = useState("");
  const token = getToken();
  const user: IUser | null = jwtDecode(token || "");
  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      reducerContext?.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      let imagePayment =
        reducerContext.contactState.contact?.imagePayment || "";
      let profileRef = reducerContext.contactState.contact?.profileRef || "";
      if (!reducerContext.contactState.contact) {
        // create must image profile & payment
        if (!contactContext.profile || !contactContext.paymentImage) {
          reducerContext?.loadDispatch({
            type: FAILED,
            payload: { fetchStatus: FAILED, message: "กรุณาเลือกรูปภาพให้ครบ" },
          });
          return;
        }
      }
      const files = [];
      if (contactContext.profile) {
        profileRef = contactContext.profile.name;
        files.push(contactContext.profile);
      }
      if (contactContext.paymentImage) {
        imagePayment = contactContext.paymentImage.name;
        files.push(contactContext.paymentImage);
      }
      await uploadFile(files);
      const data: IContactAdmin = {
        createdBy: `${user?.firstName} ${user?.lastName}`, // คนสร้างข้อมูลติดต่อ
        fullName,
        address,
        phoneNumber,
        typePayment,
        accountPayment,
        imagePayment,
        profileRef,
      };
      if (reducerContext.contactState.contact) {
        // update
        await upsertContact(
          data,
          reducerContext.contactState.contact._id || ""
        );
      } else {
        await createContact(data);
      }
      console.log("save done");

      reducerContext?.loadDispatch({
        type: SUCCESS,
        payload: {
          fetchStatus: SUCCESS,
          message: reducerContext.contactState.contact
            ? "แก้ไขข้อมูลการติดต่อเรียบร้อย"
            : "สร้างข้อมูลการติดต่อเรียบร้อย",
        },
      });
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  useEffect(() => {
    if (reducerContext.contactState.contact) {
      setFullName(reducerContext.contactState.contact.fullName);
      setPhoneNumber(reducerContext.contactState.contact.phoneNumber);
      setAddress(reducerContext.contactState.contact.address);
      setTypePayment(reducerContext.contactState.contact.typePayment);
      setAccountPayment(reducerContext.contactState.contact.accountPayment);
    }
  }, [reducerContext.contactState.contact]);
  return (
    <form onSubmit={onSubmit}>
      <div>
        <div className="my-3">
          <input
            name="fullName"
            id="fullName"
            className="w-full h-10 rounded p-2"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="ชื่อ - นามสกุล"
          />
        </div>
        <div className="my-3">
          <Tooltip title="ราคาแพ็คเกจ">
            <input
              name="phoneNumber"
              id="phoneNumber"
              className="w-full h-10 rounded p-2 "
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="เบอร์โทรศัพท์"
            />
          </Tooltip>
        </div>
        <textarea
          rows={3}
          className="w-full border-none p-2"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="ที่อยู่"
        />
        <div className="my-3">
          <select
            onChange={(e) => setTypePayment(e.target.value)}
            id="typePayment"
            value={typePayment}
            className=" border border-gray-300  text-sm rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:placeholder-gray-400 dark:focus:ring-violet-500 dark:focus:border-violet-500"
          >
            <option value="">เลือกประเภทการชำระเงิน</option>
            <option value="พร้อมเพย์">พร้อมเพย์</option>
            <option value="ธนาคารไทยพานิชย์">ธนาคารไทยพานิชย์</option>
            <option value="ธนาคารกรุงไทย">ธนาคารกรุงไทย</option>
            <option value="ธนาคารกรุงเทพ">ธนาคารกรุงเทพ</option>
            <option value="ธนาคารทหารไทยธนชาต">ธนาคารทหารไทยธนชาต</option>
            <option value="ธนาคารออมสิน">ธนาคารออมสิน</option>
            <option value="ธนาคารกรุงศรี">ธนาคารกรุงศรี</option>
            <option value="ธนาคารธ.ก.ส.">ธนาคารธ.ก.ส.</option>
          </select>
        </div>
        <div className="my-3">
          <input
            name="accountPayment"
            id="accountPayment"
            value={accountPayment}
            className="w-full h-10 rounded p-2"
            onChange={(e) => setAccountPayment(e.target.value)}
            placeholder="เลขบัญชี"
          />
        </div>
      </div>
      <button
        type="submit"
        className="w-full bg-violet-500 text-white p-3 rounded-md my-2"
      >
        {reducerContext.contactState.contact
          ? "แก้ไขข้อมูลการติดต่อ"
          : "สร้างข้อมูลการติดต่อ"}
      </button>
    </form>
  );
}
