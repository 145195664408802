import {
  FAILED,
  LOADED,
  LOADING,
  SUCCESS,
} from "../../utils/constants/constLoad";

export interface ILoad {
  fetchStatus: string;
  message: string;
}
export interface ILoadAction {
  type: string;
  payload: ILoad;
}
export const initialLoading = {
  fetchStatus: LOADED, // [success,failed, loading, loaded]
  message: "",
};

export const reducerLoading = (state: ILoad, action: ILoadAction) => {
  switch (action.type) {
    case LOADING:
      return action.payload;
    case LOADED:
      return action.payload;
    case SUCCESS:
      return action.payload;
    case FAILED:
      return action.payload;
    default:
      return state;
  }
};
