import React, { useContext, useEffect, useState } from "react";
import { ActivityContext, IActivityContext } from "../manageActivity";
import Business from "./business";
import { IActivity } from "../../../utils/types/activityType";
import { FAILED, LOADING, SUCCESS } from "../../../utils/constants/constLoad";
import { uploadFile } from "../../../services/upload/uploadAction";
import { createActivity } from "../../../services/activity/activityAction";
import { ReducerContext } from "../../../libs/routers/privateRoute";
import { updateActivity } from "../../../services/activity/activityAction";
import { IPlace } from "../../../utils/types/placeType";

function Form() {
  const activityContext = useContext<IActivityContext | null>(ActivityContext);
  const reducerContext = useContext(ReducerContext);

  const [activityName, setActivityName] = useState("");
  const [usageTime, setUsageTime] = useState("");
  const [price, setPrice] = useState<number>(0);
  const [unit, setUnit] = useState("");
  const [minPerson, setMinPerson] = useState<number>(0);
  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!activityContext?.businesState.selected?._id) {
      return reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: "กรุณาเลือกเจ้าของกิจกรรม" },
      });
    }
    try {
      reducerContext?.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      await uploadFile(activityContext?.files);
      const images = activityContext?.files.map((file: File) => {
        return file.name;
      });
      const activity: IActivity = {
        activityName,
        price,
        unit,
        minPerson,
        imageRef: images,
        placeId: activityContext?.businesState.selected._id,
        accepted: false,
        usageTime,
      };
      console.log(activity);
      
      if (activityContext.activity) {
        const activityForUpdate = {
          _id: activityContext.activity._id,
          ...activity,
          imageRef: [...images, ...activityContext.activity.imageRef], // ยังไม่ลบรูปออก
          accepted: activity.accepted,
        };
        await updateActivity(activityForUpdate);
      } else {
        await createActivity(activity);
      }

      reducerContext?.loadDispatch({
        type: SUCCESS,
        payload: {
          fetchStatus: SUCCESS,
          message: activityContext.activity
            ? "แก้ไขกิจกรรมเรียบร้อย"
            : "บันทึกกิจกรรมเรียบร้อย",
        },
      });
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  const setPlaceSelect = (placeID: string) => {
    const result: IPlace | undefined =
      activityContext?.businesState.places.find(
        (place) => place._id === placeID
      );
    if (result) {
      activityContext?.onSelectPlace(result);
    }
  };
  useEffect(() => {
    // if have a activity is edit page
    if (activityContext?.activity) {
      setPlaceSelect(activityContext?.activity.placeId);
      setActivityName(activityContext?.activity?.activityName);
      setPrice(activityContext?.activity?.price);
      setUnit(activityContext?.activity?.unit);
      setMinPerson(activityContext?.activity?.minPerson);
      setUsageTime(activityContext.activity.usageTime);
    }
  }, [activityContext?.activity, activityContext?.businesState.places]);
  return (
    <div className="flex justify-center">
      <form onSubmit={onSubmit}>
        <div className="my-3">
          <label className="mx-2 text-gray-500">ชื่อกิจกรรม</label>
          <input
            name="activityName"
            id="activityName"
            className="w-full h-10 rounded p-2"
            value={activityName}
            onChange={(e) => setActivityName(e.target.value)}
          />
        </div>
        <div className="my-3">
          <label className="mx-2 text-gray-500">ระยะเวลาในการทำกิจกรรม</label>
          <input
            name="usageTime"
            id="usageTime"
            className="w-full h-10 rounded p-2"
            value={usageTime}
            onChange={(e) => setUsageTime(e.target.value)}
          />
        </div>
        <label className="mx-2 text-gray-500">เลือกเจ้าของกิจกรรม</label>
        <div
          className="bg-white p-2 w-96 h-10 my-3 rounded text-gray-600"
          onClick={activityContext?.onHandleDialog}
        >
          <p>{activityContext?.businesState.selected?.placeName}</p>
        </div>
        <div className="my-3">
          <label className="mx-2 text-gray-500">ราคา</label>
          <input
            name="price"
            id="price"
            className="w-full h-10 rounded p-2"
            type="number"
            value={price}
            onChange={(e) => setPrice(+e.target.value)}
          />
        </div>
        <div className="my-3">
          <label className="mx-2 text-gray-500">หน่วย</label>
          <input
            name="unit"
            id="unit"
            value={unit}
            className="w-full h-10 rounded p-2"
            onChange={(e) => setUnit(e.target.value)}
          />
        </div>
        <div className="my-3">
          <label className="mx-2 text-gray-500">จำนวนสมาชิกขั้นต่ำ</label>
          <input
            name="minPerson"
            id="minPerson"
            value={minPerson}
            className="w-full h-10 rounded p-2"
            type="number"
            onChange={(e) => setMinPerson(+e.target.value)}
          />
        </div>
        <button className="bg-violet-500 w-full p-2 text-white rounded-lg my-4">
          {activityContext?.activity ? "แก้ไขกิจกรรม" : "สร้างกิจกรรม"}
        </button>
        <Business />
      </form>
    </div>
  );
}

export default Form;
