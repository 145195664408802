import React, { useRef, useReducer } from "react";
import { initialLoading, reducerLoading } from "../../services/load/reducer";
import { FAILED, LOADED, LOADING } from "../../utils/constants/constLoad";
import Loading from "../../components/loading";
import { userLogin } from "../../services/auth/action";
import { initialUser, reducerUser } from "../../services/auth/reducer";
import { setToken } from "../../libs/localStorage";
interface ILogin {
  setRole(role: string): void;
}
const LoginForm = ({ setRole }: ILogin) => {
  const [state, dispatch] = useReducer(reducerLoading, initialLoading);
  const [_, dispatchUser] = useReducer(reducerUser, initialUser);
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (usernameRef.current?.value && passwordRef.current?.value) {
      dispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      try {
        const result = await userLogin(
          usernameRef.current.value,
          passwordRef.current.value
        );
        dispatchUser(result);
        if (result.payload?.userId) {
          setToken(result.payload.token);
          setRole(result.payload.roles);
          dispatch({
            type: LOADED,
            payload: { fetchStatus: LOADED, message: "" },
          });
        } else {
          dispatch({
            type: FAILED,
            payload: {
              fetchStatus: FAILED,
              message: "invalid username or password",
            },
          });
        }
      } catch (error: any) {
        dispatch({
          type: FAILED,
          payload: { fetchStatus: FAILED, message: error.message },
        });
      }
    }
  };
  return (
    <div className="flex items-center justify-center h-screen w-full bg-gray-100">
      <Loading fetchStatus={state.fetchStatus} message={state.message} />
      <form
        className="bg-white p-8 rounded-lg shadow-xl w-96"
        onSubmit={handleSubmit}
      >
        <h2 className="text-lg font-medium mb-4">Login</h2>
        <div className="mb-4">
          <label
            className="block font-medium mb-2 text-gray-700"
            htmlFor="username"
          >
            Username
          </label>
          <input
            className="w-full border border-gray-400  p-2"
            type="text"
            id="username"
            ref={usernameRef}
          />
        </div>
        <div className="mb-4">
          <label
            className="block font-medium mb-2 text-gray-700"
            htmlFor="password"
          >
            Password
          </label>
          <input
            className="w-full border border-gray-400 p-2"
            type="password"
            id="password"
            ref={passwordRef}
          />
        </div>
        <button className="transition delay-75 ease-in-out bg-violet-400 transform hover:-translate-y-1 hover:scale-110 rounded-lg w-full h-10 text-white text-lg">
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
