import React, { useContext } from "react";
import { ReducerContext } from "../../../libs/routers/privateRoute";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { DELETE_ACTIVITIES } from "../../../utils/constants/constBusiness";
import { useNavigate } from "react-router-dom";
import { FAILED, LOADING, SUCCESS } from "../../../utils/constants/constLoad";
import { deleteActivity } from "../../../services/activity/activityAction";

function CarouselActivity() {
  const reducerContext = useContext(ReducerContext);
  const navigate = useNavigate();
  const onDeleteActivity = async (id: string) => {
    try {
      reducerContext.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      reducerContext.activityDispatch({
        type: DELETE_ACTIVITIES,
        activityId: id,
      });
      await deleteActivity(id);
      reducerContext.loadDispatch({
        type: SUCCESS,
        payload: { fetchStatus: SUCCESS, message: "ลบกิจกรรมกิจกรรมเรียบร้อย" },
      });
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };

  return (
    <div className="flex justify-center my-4">
      <div className="grid md:grid-cols-4 sm:grid-cols-1 gap-4">
        {reducerContext.activityState.activities.map((activity) => (
          <div className="bg-white rounded-md w-48 h-52" key={activity._id}>
            <div className="relative">
              <img
                src={`https://sgp1.digitaloceanspaces.com/cbt-thailand/uploads/${activity.imageRef[0]}`}
                alt={activity.activityName}
                className="object-cover h-40 w-48 rounded-md opacity-80"
              />
              <div className=" absolute right-0 top-0">
                <IconButton
                  onClick={() => onDeleteActivity(activity._id || "")}
                >
                  <Delete color="error" />
                </IconButton>
              </div>
            </div>
            <div
              className=" hover:cursor-pointer hover:underline"
              onClick={() => navigate(`/edit/activity/${activity._id}`)}
            >
              <p className="text-lg truncate text-gray-600 p-2">
                {activity.activityName}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CarouselActivity;
