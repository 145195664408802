import React, { createContext, useState } from "react";

import Layout from "../../components/layouts";
import CarouselCard from "./components/carouselCard";
import PackageTourList from "../tours";
import Activities from "../activity";

import Contacts from "../contacts";
interface ICardContext {
  onChangeCard(cardName: number): void | null;
}
export const CardContext = createContext<ICardContext>({
  onChangeCard: () => {},
});
export function HomePage() {
  const [card, setCard] = useState<number>(0); // 0=activity, 1 = contact, 2=tour

  const onChangeCard = (cardName: number) => {
    setCard(cardName);
  };

  return (
    <Layout>
      <CardContext.Provider value={{ onChangeCard }}>
        <CarouselCard />
        {card === 0 && <Activities />}
        {card === 1 && <Contacts />}
        {card === 2 && <PackageTourList />}
      </CardContext.Provider>
    </Layout>
  );
}
