import {
  FETCHS_CONTACTS,
  FETCH_CONTACT,
} from "../../utils/constants/constContact";
import { IContactAdmin } from "../../utils/types/contactAdminType";

export interface IStateContact {
  contacts: IContactAdmin[];
  contact: IContactAdmin | null;
}
export interface IActionContact {
  type: string;
  payload: IStateContact;
}
export const initialContacts: IStateContact = {
  contacts: [],
  contact: null,
};

export const contactReducer = (
  state: IStateContact,
  action: IActionContact
) => {
  switch (action.type) {
    case FETCHS_CONTACTS:
      const contacts: IStateContact = {
        contacts: action.payload.contacts,
        contact: state.contact,
      };
      return contacts;
    case FETCH_CONTACT:
      const contactData: IStateContact = {
        contacts: state.contacts,
        contact: action.payload.contact,
      };
      return contactData;
    default:
      return state;
  }
};
