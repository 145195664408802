import { Preview, TableRows, Add } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import TableActivity from "./components/tableActivity";
import CarouselActivity from "./components/carouselActivity";
import { fetchActivities } from "../../services/activity/activityAction";
import { ReducerContext } from "../../libs/routers/privateRoute";
import { FAILED, LOADED, LOADING } from "../../utils/constants/constLoad";

function Activities() {
  const reducerContext = useContext(ReducerContext);
  const navigate = useNavigate();
  const [isTable, setIsTable] = useState<boolean>(false);
  const handleIcon = (value: boolean) => {
    setIsTable(!isTable);
  };
  const onFetchActivity = async () => {
    try {
      reducerContext?.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      const result = await fetchActivities();
      reducerContext.activityDispatch(result);
      reducerContext?.loadDispatch({
        type: LOADED,
        payload: { fetchStatus: LOADED, message: "" },
      });
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  
  const onCreateActivity = () => {
    navigate("/create/activity");
  };

  useEffect(() => {
    onFetchActivity();
  }, []);

  return (
    <div className="bg-gray-100">
      <div className="flex justify-between gap-5 my-3 mx-10">
        <div>
          <Tooltip title="สร้างกิจกรรม">
            <button
              onClick={onCreateActivity}
              className="bg-violet-500 text-white rounded-md"
            >
              <Add />
            </button>
          </Tooltip>
        </div>
        <div>
          {isTable ? (
            <button onClick={() => handleIcon(false)}>
              <Preview color="primary" />
            </button>
          ) : (
            <button onClick={() => handleIcon(true)}>
              <TableRows color="primary" />
            </button>
          )}
        </div>
      </div>
      <div className="mx-10">
        {isTable ? <TableActivity /> : <CarouselActivity />}
      </div>
    </div>
  );
}

export default Activities;
