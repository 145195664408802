import jwtDecode from "jwt-decode";
import { getToken } from "../../libs/localStorage";
import { GET_USER, USER_LOGIN } from "../../utils/constants/constUser";
import { IUser } from "../../utils/types/userType";

export const initialUser: IUser = {
  userId: null,
  username: "",
  firstName: "",
  lastName: "",
  roles: "",
  profileRef: "",
  token: "",
};
export interface IUserAction {
  type: string;
  payload: IUser | null;
}

export const reducerUser = (state: IUser, action: IUserAction) => {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, ...action.payload }; // if have not user return state
    case GET_USER:
      const token = getToken();
      if (token) {
        const user: IUser = jwtDecode(token);
        return user;
      }
      return state;
    default:
      return state;
  }
};
