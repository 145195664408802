import React, { useContext } from "react";
import { ActivityContext } from "../manageActivity";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";

function AlbumActivity() {
  const activityContext = useContext(ActivityContext);
  return (
    <div className="w-full h-full">
      <label className="flex flex-col items-center justify-center w-full h-full border-dashed border-gray-300 border-2 bg-white  rounded-lg    ">
        <div className="grid md:grid-cols-3 sm:grid-cols-2 overflow-y-scroll h-96	">
          {activityContext?.activity?.imageRef.map((src, index) => (
            <div key={index} className="relative h-48">
              <img
                src={`https://sgp1.digitaloceanspaces.com/cbt-thailand/uploads/${src}`}
                alt={src}
                className="w-60 object-cover"
              />
              <div className="absolute top-0 right-0">
                <IconButton
                  onClick={() => activityContext.onDeleteImageAlbum(src)}
                  sx={{backgroundColor:"#fff"}}
                >
                  <Delete color="error" fontSize="small" />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      </label>
    </div>
  );
}

export default AlbumActivity;
