import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ReducerContext } from "../../../libs/routers/privateRoute";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
interface ITablePackageTours {
  onDelete(id: string): void;
  onEdit(id: string): void;
}
function TableTours({ onDelete, onEdit }: ITablePackageTours) {
  const reducerContext = useContext(ReducerContext);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ชื่อแพ็คเกจ</TableCell>
            <TableCell>ราคา</TableCell>
            <TableCell align="right">ประเภท</TableCell>
            {/* <TableCell align="right">จำนวน</TableCell>
            <TableCell align="right">สมาชิกขั้นต่ำ</TableCell> */}
            <TableCell align="right">action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reducerContext.packageTours.packageTours?.map((row) => (
            <TableRow
              key={row._id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.packageName}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.price}
              </TableCell>
              <TableCell align="right">{row.dayTrips}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => onEdit(row._id || "")}>
                  <Edit color="warning" />
                </IconButton>
                <IconButton onClick={() => onDelete(row._id || "")}>
                  <Delete color="error" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableTours;
