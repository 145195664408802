import React, {
  useEffect,
  useReducer,
  useState,
  createContext,
  useContext,
} from "react";
import Layout from "../../components/layouts";
import {
  IStateBusiness,
  businessReducer,
  initialBusiness,
} from "../../services/business/businessReducer";
import {
  fetchBusiness,
  selectPlace,
} from "../../services/business/businessAction";

import { FAILED, LOADED, LOADING } from "../../utils/constants/constLoad";
import Form from "./components/form";
import { IActivity } from "../../utils/types/activityType";
import ActivityDropzone from "./components/dropzone";
import { ReducerContext } from "../../libs/routers/privateRoute";
import { fetchActivity } from "../../services/activity/activityAction";
import { useParams } from "react-router-dom";
import { Collections, UploadFile } from "@mui/icons-material";
import AlbumActivity from "./components/albumActivity";
import { IPlace } from "../../utils/types/placeType";

export interface IActivityContext {
  businesState: IStateBusiness;
  handleClose(): void;
  open: boolean;
  onSelectPlace(place: IPlace): void;
  onHandleDialog(): void;
  activity: IActivity | null; // use edit page
  files: File[];
  onSelectFile(files: File[]): void;
  onDeleteImageAlbum(fileName: string): void;
}
export const ActivityContext = createContext<IActivityContext | null>(null);
function ManageActivity() {
  const { id } = useParams();
  const reducerContext = useContext(ReducerContext);
  const [businesState, dispatch] = useReducer(businessReducer, initialBusiness);
  const [files, setFiles] = useState<File[]>([]);
  const [open, setOpen] = useState(false);
  const [activity, setActivity] = useState<IActivity | null>(null);
  const [isAlbum, setIsAlbum] = useState(id !== undefined);
  const onFetchBusiness = async () => {
    try {
      reducerContext.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      const result = await fetchBusiness();
      dispatch(result);
      reducerContext.loadDispatch({
        type: LOADED,
        payload: { fetchStatus: LOADED, message: "" },
      });
    } catch (error: any) {
      reducerContext.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  const onDeleteImageAlbum = (fileName: string) => {
    if (activity) {
      setActivity((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          imageRef: prev?.imageRef.filter((src) => src !== fileName),
        };
      });
    }
  };
  // for edit
  const onFetchActivity = async (id: string) => {
    try {
      reducerContext.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      const result = await fetchActivity(id);
      setActivity(result);
      const businessResult = await fetchBusiness();
      dispatch(businessResult);
      reducerContext.loadDispatch({
        type: LOADED,
        payload: { fetchStatus: LOADED, message: "" },
      });
    } catch (error: any) {
      reducerContext.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  const onSelectPlace = (place: IPlace) => {
    const result = selectPlace({ ...businesState, selected: place });
    dispatch(result);
  };
  const onSetFile = (files: File[]) => {
    setFiles((prev) => [...prev, ...files]);
  };
  useEffect(() => {
    let isCalled = false;
    if (!isCalled && open) {
      onFetchBusiness();
    }
    if (!isCalled && id) {
      onFetchActivity(id);
    }
    return () => {
      isCalled = true;
    };
  }, [open, id]);
  const onHandleDialog = () => {
    setOpen(!open);
  };
  const activityProvider = {
    businesState,
    handleClose: onHandleDialog,
    open,
    onSelectPlace,
    onHandleDialog,
    files,
    onSelectFile: onSetFile,
    activity,
    onDeleteImageAlbum,
  };

  return (
    <Layout>
      <ActivityContext.Provider value={activityProvider}>
        <div className="grid md:grid-cols-2 my-11 mx-10 sm:grid-cols-1">
          <Form />
          <div className="flex flex-col justify-center">
            <div className="flex justify-center">
              {activity && (
                <button
                  className="text-violet-500 hover:bg-white p-2 rounded-full text-center w-32"
                  onClick={() => setIsAlbum(true)}
                >
                  <Collections />
                </button>
              )}
              <button
                className="text-violet-500 hover:bg-white p-2 rounded-full text-center w-32"
                onClick={() => setIsAlbum(false)}
              >
                <UploadFile />
              </button>
            </div>

            {isAlbum ? <AlbumActivity /> : <ActivityDropzone />}
          </div>
        </div>
      </ActivityContext.Provider>
    </Layout>
  );
}

export default ManageActivity;
