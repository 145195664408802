import ManageActivity from "../../features/activity/manageActivity";
import LoginPage from "../../features/auth/loginForm";
import ManageContact from "../../features/contacts/manageContact";
import { HomePage } from "../../features/home";
import HomePages from "../../features/homepages";
import ManageTours from "../../features/tours/manageTours";

const components = {
  login: {
    url: "/login",
    component: LoginPage,
  },
  home: {
    url: "/",
    component: HomePage,
  },
  createActivity: {
    url: "/create/activity",
    component: ManageActivity,
  },
  editActivity: {
    url: "/edit/activity/:id",
    component: ManageActivity,
  },
  createTour: {
    url: "/create/tour",
    component: ManageTours,
  },
  editTour: {
    url: "/edit/tour/:id",
    component: ManageTours,
  },
  createContact: {
    url: "/create/contact",
    component: ManageContact,
  },
  editContact: {
    url: "/edit/contact/:id",
    component: ManageContact,
  },
  homePage: {
    url: "/travel",
    component: HomePages,
  },
};

const userRole: any = {
  guest: {
    allowRoutes: [components.login, components.homePage],
    redirectRoutes: "/travel",
  },
  admin: {
    allowRoutes: [
      components.home,
      components.createActivity,
      components.editActivity,
      components.createTour,
      components.editTour,
      components.createContact,
      components.editContact,
    ],
    redirectRoutes: "/",
  },
};
export default userRole;
