import axios from "../../libs/axios";
import {
  FETCH_PLACES,
  SELECT_PLACE,
} from "../../utils/constants/constBusiness";
import { IActionBusiness, IStateBusiness } from "./businessReducer";
// change to places
export const fetchBusiness = async (): Promise<IActionBusiness> => {
  const response = await axios.get("/guest/places");
  return { type: FETCH_PLACES, payload: { places: response.data } };
};

export const selectPlace = (payload: IStateBusiness) => {
  return { type: SELECT_PLACE, payload };
};
