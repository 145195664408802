import React, { useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import { CardContext } from "..";
export default function CarouselCard() {
  const { onChangeCard } = useContext(CardContext);
  return (
    <div className="flex justify-center">
      <Carousel
        showThumbs={false}
        showIndicators={false}
        onChange={(index) => onChangeCard(index)}
        // itemsToShow={3}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`${
                hasPrev ? "absolute" : "hidden"
              } top-0 bottom-0 left-0 flex justify-center items-center p-4 opacity-40 hover:opacity-100 cursor-pointer z-20`}
              onClick={clickHandler}
            >
              <ArrowBackIos />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`${
                hasNext ? "absolute" : "hidden"
              } top-0 bottom-0 right-0 flex justify-center items-center p-4 opacity-40 hover:opacity-100 cursor-pointer z-20 `}
              onClick={clickHandler}
            >
              <ArrowForwardIos />
            </div>
          );
        }}
      >
        <div className=" h-64 mx-5 my-2">
          <img
            src="/assets/activity_storyset.png"
            alt="activity"
            className="w-full h-56 object-contain shadow-md "
          />
          <p className="legend">กิจกรรม</p>
        </div>
        <div className=" h-64 mx-5 my-2">
          <img
            src="/assets/contact_storyset.png"
            alt="package-tour"
            className="w-full h-56 object-contain shadow-md "
          />
          <p className="legend">ข้อมูลติดต่อ</p>
        </div>
        <div className=" h-64 mx-5 my-2">
          <img
            src="/assets/trip_storyset.png"
            alt="activity"
            className="w-full h-56 object-contain shadow-md "
          />
          <p className="legend">แพ็คเกจทัวร์</p>
        </div>
      </Carousel>
    </div>
  );
}
