import axios from "../../libs/axios";
import { FETCHS_PACKAGE_TOUR } from "../../utils/constants/constPackageTour";
import { IPackage } from "../../utils/types/packageType";
import { IActionTour } from "./tourReducer";

export const fetchPackageTours = async (): Promise<IActionTour> => {
  const resp = await axios.get("/packages");
  return {
    type: FETCHS_PACKAGE_TOUR,
    payload: { packageTours: resp.data, tour: null },
  };
};
export const createPackageTour = async (data: IPackage) => {
  await axios.post("/packages", data);
};

export const fetchPackageTour = async (id: string): Promise<IActionTour> => {
  const resp = await axios.get(`/packages/${id}`);
  return {
    type: FETCHS_PACKAGE_TOUR,
    payload: { packageTours: [], tour: resp.data },
  };
};

export const deletePackageTour = async (id: string) => {
  await axios.delete(`/packages/${id}`);
};

export const updatePackageTour = async(id:string,data:IPackage) => {
  await axios.put(`/packages/${id}`,data)
}