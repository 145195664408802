import { Add, Preview, TableRows } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TableContacts from "./components/tableContacts";
import CarouselContatcts from "./components/carouselContatcts";
import { useNavigate } from "react-router-dom";
import { ReducerContext } from "../../libs/routers/privateRoute";
import { FAILED, LOADED, LOADING } from "../../utils/constants/constLoad";
import {
  deleteContact,
  fetchContacts,
} from "../../services/contacts/contactAction";

function Contacts() {
  const reducerContext = useContext(ReducerContext);
  const handleIcon = (value: boolean) => {
    setIsTable(!isTable);
  };
  const navigate = useNavigate();
  const [isTable, setIsTable] = useState<boolean>(false);
  const onFetchContacts = async () => {
    try {
      reducerContext?.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      const result = await fetchContacts();
      reducerContext.contactDispatch(result);
      reducerContext?.loadDispatch({
        type: LOADED,
        payload: { fetchStatus: LOADED, message: "" },
      });
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  const onEdit = (id: string) => {
    navigate(`/edit/contact/${id}`);
  };
  const onDelete = async (
    id: string
  ) => {
    try {
      reducerContext?.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      await deleteContact(id);
      await onFetchContacts();
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  useEffect(() => {
    onFetchContacts();
  }, []);
  return (
    <div>
      <div className="flex justify-between gap-5 my-3 mx-10">
        <div>
          <Tooltip title="สร้างข้อมูลการติดต่อ">
            <button
              onClick={() => navigate("/create/contact")}
              className="bg-violet-500 text-white rounded-md"
            >
              <Add />
            </button>
          </Tooltip>
        </div>
        <div>
          {isTable ? (
            <button onClick={() => handleIcon(false)}>
              <Preview color="primary" />
            </button>
          ) : (
            <button onClick={() => handleIcon(true)}>
              <TableRows color="primary" />
            </button>
          )}
        </div>
      </div>
      <div className="mx-10">
        {isTable ? (
          <TableContacts onDelete={onDelete} onEdit={onEdit} />
        ) : (
          <CarouselContatcts onDelete={onDelete} onEdit={onEdit} />
        )}
      </div>
    </div>
  );
}

export default Contacts;
