export const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const setToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const getRole = () => {
  const token = getToken();
  if (token) {
    return "admin";
    // const payload: IUser = jwtDecode(token);
    // if (payload.roles === "admin") {
      
    // }
    // have a token but role not admin
    // removeToken();
  }
  return "guest";
};
