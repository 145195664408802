import { Chip, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DropzoneImageTour from "./dropzoneImageTour";
import { TourContext } from "../manageTours";
import { ReducerContext } from "../../../libs/routers/privateRoute";
import { FAILED, LOADING, SUCCESS } from "../../../utils/constants/constLoad";
import { IPackage } from "../../../utils/types/packageType";
import { uploadFile } from "../../../services/upload/uploadAction";
import {
  createPackageTour,
  updatePackageTour,
} from "../../../services/tours/tourAction";
import jwtDecode from "jwt-decode";
import { IUser } from "../../../utils/types/userType";
import { getToken } from "../../../libs/localStorage";
import ContactDialog from "./contactDialog";

function FormTour() {
  const reducerContext = useContext(ReducerContext);
  const tourContext = useContext(TourContext);
  const token = getToken();
  const user: IUser | null = jwtDecode(token || "");
  const [packageName, setPackageName] = useState("");
  const [mark, setMark] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [dayForrents, setDayForrents] = useState<string[]>([]);
  const onSelectDayForrent = (value: string) => {
    setDayForrents((pre) => [...pre, value]);
  };
  const onDeleteDayForrents = (value: string) => {
    setDayForrents((pre) => pre.filter((day) => day !== value));
  };
  const onSubmitPackageDetail = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      reducerContext?.loadDispatch({
        type: LOADING,
        payload: { fetchStatus: LOADING, message: "" },
      });
      if (!tourContext.file && !reducerContext.packageTours.tour) {
        reducerContext?.loadDispatch({
          type: FAILED,
          payload: { fetchStatus: FAILED, message: "กรุณาเลือกรูปภาพแพ็คเกจ" },
        });
        return;
      }
      if (!reducerContext.contactState.contact) {
        reducerContext?.loadDispatch({
          type: FAILED,
          payload: {
            fetchStatus: FAILED,
            message: "กรุณาเลือกข้อมูลการติดต่อสำหรับผู้ดูแลแพ็คเกจนี้",
          },
        });
        return;
      }
      let packageImage = reducerContext.packageTours.tour?.packageImage || "";
      if (tourContext.file) {
        await uploadFile([tourContext.file]);
        packageImage = tourContext.file.name;
      }

      const data: IPackage = {
        packageName,
        price,
        description,
        dayForrent: dayForrents,
        mark,
        dayTrips: reducerContext.packageTours.tour?.dayTrips || "1d",
        round: reducerContext.packageTours.tour?.round || [],
        introduce: reducerContext.packageTours.tour?.introduce || "",
        packageImage,
        createdBy: `${user?.firstName} ${user?.lastName}`,
        contactAdmin: reducerContext.contactState.contact,
      };
      if (reducerContext.packageTours.tour) {
        await updatePackageTour(
          reducerContext.packageTours.tour?._id || "",
          data
        );
      } else {
        await createPackageTour(data);
      }

      reducerContext?.loadDispatch({
        type: SUCCESS,
        payload: {
          fetchStatus: SUCCESS,
          message: reducerContext.packageTours.tour
            ? "แก้ไขแพ็คเกจเรียบร้อย"
            : "สร้างแพ็คเกจเรียบร้อย",
        },
      });
    } catch (error: any) {
      reducerContext?.loadDispatch({
        type: FAILED,
        payload: { fetchStatus: FAILED, message: error.message },
      });
    }
  };
  useEffect(() => {
    if (reducerContext.packageTours.tour) {
      setPackageName(reducerContext.packageTours.tour.packageName);
      setPrice(reducerContext.packageTours.tour.price);
      setDescription(reducerContext.packageTours.tour.description);
      setDayForrents(reducerContext.packageTours.tour.dayForrent);
      setMark(reducerContext.packageTours.tour.mark);
    }
  }, [reducerContext.packageTours.tour]);
  return (
    <div className=" grid grid-cols-2 mx-10">
      <form onSubmit={onSubmitPackageDetail}>
        <div>
          <div className="my-3">
            <input
              name="packageName"
              id="packageName"
              className="w-full h-10 rounded p-2"
              value={packageName}
              onChange={(e) => setPackageName(e.target.value)}
              placeholder="ชื่อแพ็คเกจทัวร์"
            />
          </div>
          <div className="my-3">
            <Tooltip title="ราคาแพ็คเกจ">
              <input
                name="price"
                id="price"
                className="w-full h-10 rounded p-2"
                type="number"
                value={price}
                onChange={(e) => setPrice(+e.target.value)}
                placeholder="ราคา"
              />
            </Tooltip>
          </div>
          <textarea
            rows={12}
            className="w-full border-none p-2"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="รายละเอียดแพ็คเกจ"
          />
          <div
            className="my-3 w-full h-10 rounded p-2 bg-white"
            onClick={() => tourContext.handleOpen(true)}
          >
            <p>
              {reducerContext.contactState.contact?.fullName ||
                reducerContext.packageTours.tour?.contactAdmin.fullName ||
                "กรุณาเลือกผู้ดูแลแพ็คเกจ"}
            </p>
          </div>
          <div className="my-3">
            <input
              name="mark"
              id="mark"
              value={mark}
              className="w-full h-10 rounded p-2"
              onChange={(e) => setMark(e.target.value)}
              placeholder="หมายเหตุ"
            />
          </div>
          <div className="my-3">
            <label className="mx-2 text-gray-500 block">
              วันที่สามารถซื้อแพ็คเกจได้ เช่น ส. อา.{" "}
            </label>
            <Chip
              label="อา."
              onClick={() => onSelectDayForrent("Sunday")}
              onDelete={() => onDeleteDayForrents("Sunday")}
              sx={{
                backgroundColor: dayForrents.includes("Sunday")
                  ? "#ddd6fe"
                  : "#fff",
                margin: "10px",
                border: dayForrents.includes("Sunday")
                  ? "2px solid #8b5cf6"
                  : "",
              }}
            />
            <Chip
              label="จ."
              onClick={() => onSelectDayForrent("Monday")}
              onDelete={() => onDeleteDayForrents("Monday")}
              sx={{
                backgroundColor: dayForrents.includes("Monday")
                  ? "#ddd6fe"
                  : "#fff",
                margin: "10px",
                border: dayForrents.includes("Monday")
                  ? "2px solid #8b5cf6"
                  : "",
              }}
            />
            <Chip
              label="อ."
              onClick={() => onSelectDayForrent("Tuesday")}
              onDelete={() => onDeleteDayForrents("Tuesday")}
              sx={{
                backgroundColor: dayForrents.includes("Tuesday")
                  ? "#ddd6fe"
                  : "#fff",
                margin: "10px",
                border: dayForrents.includes("Tuesday")
                  ? "2px solid #8b5cf6"
                  : "",
              }}
            />
            <Chip
              label="พ."
              onClick={() => onSelectDayForrent("Wednesday")}
              onDelete={() => onDeleteDayForrents("Wednesday")}
              sx={{
                backgroundColor: dayForrents.includes("Wednesday")
                  ? "#ddd6fe"
                  : "#fff",
                margin: "10px",
                border: dayForrents.includes("Wednesday")
                  ? "2px solid #8b5cf6"
                  : "",
              }}
            />
            <Chip
              label="พฤ."
              onClick={() => onSelectDayForrent("Thursday")}
              onDelete={() => onDeleteDayForrents("Thursday")}
              sx={{
                backgroundColor: dayForrents.includes("Thursday")
                  ? "#ddd6fe"
                  : "#fff",
                margin: "10px",
                border: dayForrents.includes("Thursday")
                  ? "2px solid #8b5cf6"
                  : "",
              }}
            />
            <Chip
              label="ศ."
              onClick={() => onSelectDayForrent("Friday")}
              onDelete={() => onDeleteDayForrents("Friday")}
              sx={{
                backgroundColor: dayForrents.includes("Friday")
                  ? "#ddd6fe"
                  : "#fff",
                margin: "10px",
                border: dayForrents.includes("Friday")
                  ? "2px solid #8b5cf6"
                  : "",
              }}
            />
            <Chip
              label="ส."
              onClick={() => onSelectDayForrent("Saturday")}
              onDelete={() => onDeleteDayForrents("Saturday")}
              sx={{
                backgroundColor: dayForrents.includes("Saturday")
                  ? "#ddd6fe"
                  : "#fff",
                margin: "10px",
                border: dayForrents.includes("Saturday")
                  ? "2px solid #8b5cf6"
                  : "",
              }}
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-violet-500 text-white p-3 rounded-md my-2"
        >
          {reducerContext.packageTours.tour ? "แก้ไขแพ็คเกจ" : "สร้างแพ็คเกจ"}
        </button>
        <ContactDialog />
      </form>
      <DropzoneImageTour />
    </div>
  );
}

export default FormTour;
